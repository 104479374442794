
	import DateRangePicker from 'vue2-daterange-picker'

	let debounceTimer = null

	export default {
		components: {
			DateRangePicker
		},
		data () {
			return {
				loading: {
					orders: true,
					order: false
				},
				orders: {
					data: [],
					total: 0,
					offset: 0
				},
				selectedOrder: {},
				filters: {
					searchTerm: '',
					customer: null,
					paymentMethodId: null,
					status: null
				},
				dateRange: {
					startDate: null,
					endDate: null
				},
				paymentMethods: null,
				customers: [],
				orderStatus: [
					{
						text: 'open',
						value: 'open'
					},
					{
						text: 'closed',
						value: 'closed'
					},
					{
						text: 'partially refunded',
						value: 'partially_refunded'
					},
					{
						text: 'fully refunded',
						value: 'fully_refunded'
					},
					{
						text: 'cancelled',
						value: 'cancelled'
					}
				],
				pluralization: {
					dispatched: {
						text: 'dispatch',
						index: 2
					},
					cancelled: {
						text: 'cancel',
						index: 2
					}
				}
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employee () {
				return this.$store.state.employee
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			employeeShift () {
				return this.$store.state.employeeShift
			},
			otpModalType: {
				get () {
					return this.$store.state.otpModalType
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpModalType', value })
				}
			},
			settings () {
				return this.$store.state.settings
			},
			isPrimaryDevice () {
				return this.$store.state.device.is_primary
			},
			orderFilters () {
				return {
					merchant_id: this.merchant.id,
					search_term: this.filters.searchTerm,
					customer_id: this.filters.customer
						? this.filters.customer.value
						: null,
					from_date: this.dateRange.startDate
						? this.$moment(this.dateRange.startDate).startOf('day')
						: null,
					to_date: this.dateRange.endDate ? this.$moment(this.dateRange.endDate).endOf('day') : null,
					payment_method_id: this.filters.paymentMethodId,
					status: this.filters.status || ['open', 'closed', 'completed']
				}
			},
			shareReceipt: {
				get () {
					return this.$store.state.shareReceipt
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'shareReceipt',
						value
					})
				}
			},
			computedPrice () {
				const price = {
					subtotal: this.selectedOrder.sub_total || 0,
					tax: this.selectedOrder.total_tax || 0
				}

				if (
					this.selectedOrder.id && (
						!this.selectedOrder.custom_attributes.tax_calculation_phase ||
						this.selectedOrder.custom_attributes.tax_calculation_phase === 'before_discount'
					) && this.settings.general.include_tax_in_subtotal
				) {
					price.subtotal -= this.selectedOrder.total_discounted_amount
					price.tax -= this.selectedOrder.total_discounted_tax
				}

				return price
			},
			orderOutstandingAmount () {
				return this.selectedOrder.credits
					? this.$currency.transformNumber(this.selectedOrder.payment_methods.reduce((sum, pm) => {
						if (pm.slug === 'credit') {
							sum += pm.amount
						}

						return sum
					}, 0) - this.selectedOrder.credits.reduce((sum, c) => {
						sum += c.amount

						return sum
					}, 0))
					: 0
			},
			creditsPaid () {
				return this.selectedOrder?.credits.filter(c => c.payment_method.slug !== 'credit') || []
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			},
			kotHistory () {
				const histories = []
				const kotGroups = this.selectedOrder.kot_items
					? this.groupBy(this.selectedOrder.kot_items, 'kot_id')
					: {}

				if (Object.keys(kotGroups).length) {
					for (const i in kotGroups) {
						histories.push(kotGroups[i])
					}
				}

				return histories
			}
		},
		watch: {
			selectedOrder (newOrder, oldOrder) {
				if (newOrder.id !== oldOrder.id && this.selectedOrder?.payment_methods?.filter(pm => pm.slug === 'credit').length && !this.isMiniPlan) {
					this.getOrderDetails()
				}
			}
		},
		async beforeMount () {
			const paymentMethods = await this.$bridge.getPaymentMethods(this.deviceId, '')

			this.paymentMethods = typeof paymentMethods === 'string' ? JSON.parse(paymentMethods) : paymentMethods
			await this.getOrders({})
			this.loading.orders = false
			requestAnimationFrame(() => {
				this.$refs.ordersBlock?.addEventListener('scroll', this.loadMoreOrders)
			})
		},
		mounted () {
			this.$root.$on('order-update', () => {
				if (this.bridgeName !== 'ANDROID') { // blocked in android because out of sync call to getOrders causes pay credits to disappear
					this.getOrders({})
				}
			})
			this.$root.$on('refund-verified', () => this.$bvModal.show('issue-refund'))
			this.$root.$on('edit-sale-verified', this.editOrder)
		},
		destroyed () {
			this.$root.$off('order-update')
			this.$root.$off('refund-verified')
			this.$root.$off('edit-sale-verified', this.editOrder)
		},
		methods: {
			captureEmailPhone (type) {
				this.shareReceipt = {
					sendReceiptType: type,
					receiptCode: this.selectedOrder.receipt_code,
					customerPhone: this.selectedOrder.customer?.phone || null,
					customerEmail: this.selectedOrder.customer?.email || null
				}
			},
			searchOrders () {
				clearTimeout(this.searchTimer)
				this.searchTimer = setTimeout(() => {
					this.getOrders({})
				}, 500)
			},
			async getOrders ({ offset }) {
				let orders = await this.$store.dispatch('bridgeCall', {
					methodName: 'getOrders',
					args: [this.deviceId, this.objToJson({
						...this.orderFilters,
						offset
					})]
				})

				orders = typeof orders === 'string' ? JSON.parse(orders) : orders

				if (orders) {
					if (orders.data[0] && Object.prototype.hasOwnProperty.call(orders.data[0], 'refund')) {
						orders.data = orders.data.map((order) => {
							return {
								...order,
								refunds: order.refund ? [order.refund] : []
							}
						})
					}

					if (offset) {
						orders.data = this.orders.data.concat(orders.data)
					}

					this.orders = orders

					if (this.selectedOrder?.id) {
						const selectedOrder = this.orders.data.find(o => o.id === this.selectedOrder.id)

						this.selectedOrder = selectedOrder || this.orders.data[0] || {}
					} else {
						this.selectedOrder = this.orders.data[0] || {}
					}
				} else {
					this.store.dispatch('checkPrimaryDevice')
				}
			},
			async getCustomers ({ searchTerm, offset }) {
				let customers = await this.$store.dispatch('bridgeCall', {
					methodName: 'getCustomers',
					args: [this.deviceId, this.objToJson({
						merchant_id: this.merchant.id,
						search_term: searchTerm || null,
						offset
					})]
				})

				customers = typeof customers === 'string' ? JSON.parse(customers) : customers
				this.customers = customers.data.map((customer) => {
					const fullName = `${customer.first_name} ${customer.last_name}`

					return {
						label: fullName,
						name: fullName,
						value: customer.phone
					}
				})
			},
			clearFilters () {
				this.filters = {
					searchTerm: '',
					customer: null,
					paymentMethodId: null,
					status: null
				}
				this.dateRange = { startDate: null, endDate: null }
				this.getOrders({})
			},
			selectOrder ($event, order) {
				$event.currentTarget.closest('.list-group').childNodes.forEach((list) => {
					list.classList.remove('active')
				})
				$event.currentTarget.classList.add('active')
				this.selectedOrder = order
			},
			loadMoreOrders ($event) {
				const el = $event.currentTarget

				clearTimeout(debounceTimer)
				debounceTimer = setTimeout(() => {
					if (
						this.orders.data.length < this.orders.total &&
						Math.round(el.scrollTop) > (el.scrollHeight - el.offsetHeight) - 10
					) {
						this.getOrders({ offset: this.orders.offset + (this.appVersionNumber >= 4024 ? 20 : 50) })
					}
				}, 100)
			},
			printReceipt (type) {
				window.printOrder({
					...this.selectedOrder,
					customers: this.selectedOrder.customer ? [this.selectedOrder.customer] : [],
					reprint: true
				}, type)
			},
			printInvoice (type, invoiceType) {
				window.printOrderInvoice({
					...this.selectedOrder,
					customers: this.selectedOrder.customer ? [this.selectedOrder.customer] : [],
					reprint: true
				}, type, invoiceType)
			},
			issueRefund () {
				if (this.employeePolicy.isAdmin || (this.employeePolicy.sales && this.employeePolicy.sales.includes('refund'))) {
					this.$bvModal.show('issue-refund')
				} else {
					this.otpModalType = 'refund'
				}
			},
			async updateRefund () {
				await this.getOrders({ offset: this.orders.offset })

				if (+this.settings.general.print_refund_reciept || +this.settings.receipt.preview) {
					window.printOrder({
						...this.selectedOrder,
						customers: this.selectedOrder.customer ? [this.selectedOrder.customer] : []
					})
				}
			},
			initOrderEdit () {
				if (this.employeePolicy.isAdmin || (this.employeePolicy.sales && this.employeePolicy.sales.includes('edit'))) {
					this.editOrder()
				} else {
					this.otpModalType = 'edit-sale'
				}
			},
			editOrder () {
				this.$store.commit('setState', {
					key: 'selectedOrder',
					value: this.selectedOrder
				})

				if (Array.isArray(this.selectedOrder.discounts) && this.selectedOrder.discounts.length) {
					this.$store.commit('setState', {
						key: 'selectedDiscount',
						value: this.selectedOrder.discounts[0]
					})
				}

				this.addOrderItemsToCart(this.selectedOrder)
				this.$emit('update:sell')
			},
			async getOrderDetails () {
				const date = new Date()

				if (this.selectedOrder.order_id) {
					this.loading.order = true

					const orderDetails = await this.$store.dispatch(
						'getOrderDetails',
						this.selectedOrder.order_id
					)

					if (orderDetails.id === +this.selectedOrder.order_id) {
						const creditsPaidAtAdmin = orderDetails.payments.reduce((acc, p) => {
							if (p.customAttributes?.source === 'admin' && p.paymentMethod !== 'credit') {
								acc.push({
									id: this.getUniqueId(p.id),
									merchant_id: this.merchant.id,
									device_id: this.deviceId,
									employee_shift_id: null,
									customer_id: this.selectedOrder.customer.id,
									order_id: this.selectedOrder.id,
									credit_code: `${this.deviceId}${new Date(p.createdAt).valueOf()}`,
									amount: p.amount,
									payment_method: p.paymentMethod,
									type: 'debit',
									notes: '',
									custom_attributes: this.objToJson({
										source: 'admin'
									}),
									is_synced: true,
									created_at: this.$moment.utc(p.createdAt).toDate(),
									updated_at: date
								})
							}

							return acc
						}, [])
						const creditsPaidAtComplete = this.selectedOrder.credits?.reduce((acc, c) => {
							if (c.custom_attributes.source === 'complete') {
								acc.push({
									...c,
									custom_attributes: this.objToJson(c.custom_attributes),
									payment_method: c.payment_method.slug,
									customer_id: c.customer.id,
									updated_at: date
								})
							}

							return acc
						}, []) || []
						const totalCredits = creditsPaidAtAdmin.concat(creditsPaidAtComplete)
						const order = {
							id: this.selectedOrder.id,
							credits: totalCredits,
							status: this.appVersionNumber >= 4039 && this.bridgeName === 'ELECTRON' ? orderDetails.dueAmount > 0 ? 'open' : 'closed' : this.selectedOrder.status,
							due_amount: orderDetails.dueAmount,
							refresh: false
						}

						await this.$store.dispatch('bridgeCall', {
							methodName: 'insert',
							args: [
								'Order',
								this.bridgeName === 'ANDROID' ? this.objToJson(order) : order,
								true
							]
						})

						this.selectedOrder.credits = totalCredits.map(c => ({
							...c,
							custom_attributes: JSON.parse(c.custom_attributes),
							payment_method: this.paymentMethods.find(pm => pm.slug === c.payment_method)
						}))

						if (orderDetails?.id === +this.selectedOrder.order_id) {
							this.$set(this.selectedOrder, 'due_amount', this.$currency.transformNumber(orderDetails.dueAmount))
							this.$set(this.selectedOrder, 'original_due_amount', orderDetails.dueAmount)
						}
					}

					this.loading.order = false
				}
			},
			async showPayCreditModal () {
				await this.getOrderDetails()
				this.$bvModal.show('pay-credit-modal')
			},
			async updateCredit (credit) {
				const credits = this.selectedOrder.credits.map((c) => {
					return {
						...c,
						payment_method: c.payment_method.slug,
						custom_attributes: this.objToJson(c.custom_attributes)
					}
				})

				const currentDueAmount = this.$currency.transformNumber(this.selectedOrder.due_amount - credit.amount)
				const order = {
					id: this.selectedOrder.id,
					credits: credits.concat(credit),
					status: this.appVersionNumber >= 4039 && this.bridgeName === 'ELECTRON' ? currentDueAmount > 0 ? 'open' : 'closed' : this.selectedOrder.status,
					due_amount: currentDueAmount,
					refresh: false
				}

				await this.$store.dispatch('bridgeCall', {
					methodName: 'insert',
					args: [
						'Order',
						this.bridgeName === 'ANDROID' ? this.objToJson(order) : order,
						true
					]
				})
				this.selectedOrder.credits = this.selectedOrder.credits.concat({
					...credit,
					payment_method: this.paymentMethods.find(pm => pm.slug === credit.payment_method),
					custom_attributes: JSON.parse(credit.custom_attributes)
				})

				await this.getOrders({ offset: this.orders.offset })
				this.$set(this.selectedOrder, 'due_amount', currentDueAmount)
			},
			async exportOrders () {
				await this.$store.dispatch('bridgeCall', {
					methodName: 'exportSheet',
					args: [this.deviceId, 'Order', this.objToJson({
						...this.orderFilters,
						offset: -1
					})]
				})
			}
		}
	}

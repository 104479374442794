import { render, staticRenderFns } from "./tables.vue?vue&type=template&id=62a13742"
import script from "./tables.vue?vue&type=script&lang=js"
export * from "./tables.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TablesModal: require('/home/ubuntu/complete-nuxt-js-v410/components/tables-modal.vue').default,OrdersModal: require('/home/ubuntu/complete-nuxt-js-v410/components/orders-modal.vue').default,Checkout: require('/home/ubuntu/complete-nuxt-js-v410/components/checkout.vue').default})


	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				loading: false,
				floors: [],
				filters: {
					floor_id: null,
					status: null
				},
				status: [
					{
						name: 'free',
						value: 'free'
					},
					{
						name: 'occupied',
						value: 'occupied'
					}
				],
				selectedFloor: null,
				tables: [],
				selectedTable: null,
				waiters: [],
				selectedWaiter: null,
				tempTable: null,
				tableOrders: [],
				tableOrder: {},
				tableOrderEmployee: null,
				lastSelectedTableOrderId: null,
				processingOrder: false,
				timer: null,
				pluralization: {
					dispatched: {
						text: 'dispatch',
						index: 2
					},
					cancelled: {
						text: 'cancel',
						index: 2
					}
				},
				splitBillItemFields: [
					{
						key: 'checkbox',
						label: '',
						tdClass: 'align-middle'
					},
					{
						key: 'variation_name',
						label: 'item name',
						tdClass: 'align-middle'
					},
					{
						key: 'price',
						label: 'price',
						tdClass: 'align-middle'
					},
					{
						key: 'quantity',
						label: 'quantity',
						tdClass: 'align-middle'
					}
				],
				splitBillItems: [],
				selectedSplitBillItems: [],
				splitOkDisabled: false,
				keystrokes: '',
				numberOfGuest: null,
				tableStatus: [
					{
						text: 'free',
						value: 'free'
					},
					{
						text: 'blocked',
						value: 'blocked'
					}
				]
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			appVersion () {
				return this.$store.state.appVersion
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employee () {
				return this.$store.state.employee
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			cart () {
				return this.$store.state.cart
			},
			checkout () {
				return this.$store.state.checkout
			},
			lastSelectedTableId () {
				return this.$store.state.lastSelectedTableId
			},
			selectedOrder () {
				return this.$store.state.selectedOrder
			},
			discounts () {
				return this.$store.getters.orderDiscounts
			},
			settings () {
				return this.$store.state.settings
			},
			isSecondaryDevice () {
				return !!(this.$store.state.settings.general.enable_token_number_system &&
					!this.$store.state.device.is_primary)
			},
			isOnline () {
				return this.$store.state.isOnline
			},
			kotHistory () {
				const histories = []
				const kotGroups = this.tableOrder.kot_items
					? this.groupBy(this.tableOrder.kot_items, 'kot_id')
					: {}

				if (Object.keys(kotGroups).length) {
					for (const i in kotGroups) {
						histories.push(kotGroups[i])
					}
				}

				return histories
			},
			otpModalType: {
				get () {
					return this.$store.state.otpModalType
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpModalType', value })
				}
			},
			tip: {
				get () {
					return this.$store.state.tip
				},
				set (value) {
					this.$store.commit('setState', { key: 'tip', value })
				}
			},
			selectedTables: {
				get () {
					return this.$store.state.selectedTables
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'selectedTables',
						value
					})
				}
			},
			selectedOrders () {
				return this.$store.state.selectedOrders
			},
			selectedDiscount: {
				get () {
					return this.$store.state.selectedDiscount
				},
				set (discount) {
					this.$store.commit('setState', {
						key: 'selectedDiscount',
						value: discount
					})
				}
			},
			computedPrice () {
				const price = {
					subtotal: this.tableOrder.sub_total || 0,
					tax: this.tableOrder.total_tax || 0
				}

				if (
					this.tableOrder.id && (
						!this.tableOrder.custom_attributes.tax_calculation_phase ||
						this.tableOrder.custom_attributes.tax_calculation_phase === 'before_discount'
					) && this.settings.general.include_tax_in_subtotal
				) {
					price.subtotal -= this.tableOrder.total_discounted_amount
					price.tax -= this.tableOrder.total_discounted_tax
				}

				return price
			},
			keyboardShortcuts () {
				return this.$store.state.keyboardShortcuts
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		watch: {
			selectedTable (table) {
				this.$store.commit('setState', {
					key: 'lastSelectedTableId',
					value: table ? table.id : null
				})
			},
			tableOrder (order) {
				this.lastSelectedTableOrderId = order.id
				this.tableOrderEmployee = this.$store.state.employees.find(e => e.id === this.tableOrder.employee_id)
			},
			checkout (checkout) {
				if (!checkout.show) {
					this.processingOrder = false

					if (this.isSecondaryDevice) {
						this.refreshTableAndOrders()
					}
				}
			}
		},
		mounted () {
			this.$root.$on('order-update', this.refreshTableAndOrders)
			this.$root.$on('cancel-order-verified', this.cancelOrder)
			this.$root.$on('update-table-order', this.updateTableOrder)
			this.$root.$on('edit-billed-order-verified', this.editOrder)
			document.addEventListener('keydown', this.keyboardEvents)
		},
		destroyed () {
			this.$root.$off('order-update', this.refreshTableAndOrders)
			this.$root.$off('cancel-order-verified', this.cancelOrder)
			this.$root.$off('update-table-order', this.updateTableOrder)
			this.$root.$off('edit-billed-order-verified', this.editOrder)
			document.removeEventListener('keydown', this.keyboardEvents)
		},
		beforeDestroy () {
			if (!this.selectedOrder) {
				this.selectedDiscount = null
			}

			this.$root.$off('cancel-order-verified', this.cancelOrder)
		},
		methods: {
			defaultLayout (index) {
				return {
					left: 100 * index + 'px',
					top: '0px',
					width: '100px',
					height: '100px'
				}
			},
			async getFloors () {
				this.loading = true

				const floors = await this.$store.dispatch('bridgeCall', {
					methodName: 'getFloors',
					args: [this.deviceId, this.locationId, this.objToJson({
						merchant_id: this.merchant.id
					})]
				})

				this.floors = typeof floors === 'string' ? JSON.parse(floors) : floors
				this.floors = this.floors.data || this.floors

				if (this.floors.length) {
					this.selectedFloor = this.floors[0]
				}

				this.loading = false
			},
			async getTableOrders () {
				if (this.selectedTable !== null) {
					let orders = await this.$store.dispatch('bridgeCall', {
						methodName: 'getOrders',
						args: [this.deviceId, this.objToJson({
							table_id: ((this.selectedTable && this.selectedTable.id) || null),
							status: ['pending', 'billed']
						})]
					})

					orders = (typeof orders === 'string' ? JSON.parse(orders) : orders).data.reverse()
					this.tableOrders = JSON.parse(this.objToJson(orders))
					this.tableOrder = orders.find(o => o.id === this.lastSelectedTableOrderId) || orders[0] || {}

					// fallback to handle when mismatch in table occupied status
					await this.$store.dispatch('updateTableStatus', [this.selectedTable.id])
					this.refreshTable()
				}
			},
			async getWaiters () {
				let waiters = await this.$bridge.getEmployees(this.deviceId, this.locationId, this.objToJson({
					type: 'waiter',
					offset: -1
				}))

				waiters = (typeof waiters === 'string' ? JSON.parse(waiters) : waiters)
				this.waiters = waiters.data || waiters
				setTimeout(() => {
					document.querySelector('#assign-waiter-modal .vs__search')?.focus()
				}, 500)
			},
			selectTable (table) {
				this.$store.commit('resetCart')
				table = JSON.parse(this.objToJson(table))
				this.selectedTable = table
				this.getTableOrders()

				if (this.selectedTable.is_occupied || (this.selectedTable.status && ['billed', 'occupied', 'blocked'].includes(this.selectedTable.status))) {
					this.$bvModal.hide('table-layout-modal')
				} else {
					this.$bvModal.hide('table-layout-modal')
					this.$bvModal.show('assign-waiter-modal')
				}
			},
			selectOrder (order) {
				this.tableOrder = order
				this.selectedDiscount = order.discounts && order.discounts.length
					? order.discounts[0]
					: null
			},
			updateTableOrder () {
				this.tableOrder = {
					...this.tableOrder,
					sub_total: this.cart.price.subtotal,
					total_tax: this.cart.price.tax,
					taxes: this.cart.price.taxes,
					total_discount: this.cart.price.discount,
					discounts: this.selectedDiscount ? [this.selectedDiscount] : [],
					total_charge: this.cart.price.charge,
					charges: this.cart.price.charges,
					tip: this.cart.price.tip,
					total_price: this.cart.price.total,
					custom_attributes: {
						...this.tableOrder.custom_attributes,
						tip: this.tip
					}
				}
			},
			async initNewOrder () {
				if (await this.$refs.validator.validate()) {
					this.$store.commit('resetCart')
					this.selectedTables = []
					this.$store.commit('setCart', {
						table: this.selectedTable ? { ...this.selectedTable } : null,
						waiter: this.selectedWaiter ? { ...this.selectedWaiter } : null,
						numberOfGuest: this.numberOfGuest,
						tableStartTime: new Date()
					})
					this.$emit('update:sell')
				}
			},
			switchTable () {
				this.$bvModal.show('table-layout-modal')
			},
			restorePreviousTable () {
				if (this.selectedTable == null) {
					this.selectedTable = this.tempTable
					this.tempTable = null
				}
			},
			createNewOrder () {
				this.$store.commit('resetCart')

				if (this.selectedTable) {
					this.$store.commit('setCart', { table: { ...this.selectedTable } })
				}

				this.$emit('update:sell')
			},
			removeTip () {
				this.tip = null
				this.$store.dispatch('cartCalculation')
				this.updateTableOrder()
			},
			initOrderEdit () {
				if (this.tableOrder.status === 'billed') {
					this.employeePolicy.isAdmin ? this.editOrder() : (this.otpModalType = 'edit-billed-order')
				} else {
					this.editOrder()
				}
			},
			editOrder () {
				this.$store.commit('setState', {
					key: 'selectedOrder',
					value: this.tableOrder
				})

				if (this.tableOrder.discounts && this.tableOrder.discounts.length) {
					this.selectedDiscount = this.tableOrder.discounts[0]
				}

				this.tableOrder.table = { ...this.selectedTable }
				this.addOrderItemsToCart(this.tableOrder)
				this.$emit('update:sell')
			},
			transferTable () {
				this.editOrder()
				this.$store.commit('setState', {
					key: 'checkout',
					value: {
						show: true,
						isTrusted: true,
						action: 'transferTable'
					}
				})
			},
			completeOrder () {
				clearTimeout(this.timer)
				this.timer = setTimeout(async () => {
					this.processingOrder = true
					this.$store.commit('setState', {
						key: 'selectedOrder',
						value: this.tableOrder
					})
					await this.addOrderItemsToCart(this.tableOrder)

					if (this.cart.items.length !== this.tableOrder.items.length) {
						const missingItems = this.tableOrder.items.reduce((items, item) => {
							if (this.cart.items.findIndex(i => i.id === item.variation_id) === -1) {
								items.push(item.variation_name)
							}

							return items
						}, [])

						return this.$swal({
							title: this.$t('tableItemMissingError.title', [
								missingItems.length,
								this.$tc('item', missingItems.length > 1 ? 2 : 1)
							]),
							text: this.$t('tableItemMissingError.text', [
								missingItems.join(', '),
								this.$t(missingItems.length > 1 ? 'are' : 'is')
							]),
							icon: 'error',
							button: this.$t('ok')
						})
					}

					this.$store.commit('setState', {
						key: 'checkout',
						value: {
							show: true,
							isTrusted: true
						}
					})
				}, 500)
			},
			cancelConfirmation () {
				if (!this.isOnline && this.tableOrder?.custom_attributes?.order_type === 'dine_in' && this.tableOrder?.custom_attributes?.platform === 'online') {
					return this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('offlineError.text'),
						icon: 'error',
						button: this.$t('ok')
					})
				} else {
					this.$swal({
						title: this.$t('areYouSure'),
						text: this.$t('cancelOrderWarning'),
						icon: 'warning',
						buttons: [this.$t('no'), this.$t('yes')],
						dangerMode: true
					}).then((response) => {
						if (response) {
							if (this.employeePolicy.isAdmin || (this.employeePolicy.tables && this.employeePolicy.tables.includes('cancel order'))) {
								this.cancelOrder()
							} else {
								this.otpModalType = 'cancel-order'
							}
						}
					})
				}
			},
			async cancelOrder () {
				const date = new Date()
				const order = {
					id: this.tableOrder.id,
					price_category: this.tableOrder.price_category,
					status: 'cancelled',
					receipt_code: this.tableOrder.receipt_code,
					ref_code: this.tableOrder.ref_code,
					tables: this.tableOrder.tables?.map((table) => {
						return {
							...table,
							layout: typeof table.layout === 'object' ? this.objToJson(table.layout) : this.objToJson(table.layout),
							custom_attributes: this.objToJson(table.custom_attributes),
							updated_at: date
						}
					}),
					kot_items: this.tableOrder.kot_items.map(i => ({
						...i,
						unit_measure_type: typeof i.unit_measure_type === 'object'
							? this.objToJson(i.unit_measure_type)
							: i.unit_measure_type,
						modifiers: this.objToJson(i.modifiers),
						status: 'cancelled',
						status_history: this.objToJson(i.status_history.concat([
							{
								status: 'cancelled',
								created_at: date
							}
						])),
						updated_at: date
					})),
					updated_at: date,
					is_synced: false
				}

				if (order.kot_items.length && [
					'restaurant', 'qsr'
				].includes(this.merchant.businessType)) {
					const kotItems = this.tableOrder.kot_items.reduce((items, item) => {
						if (!['dispatched', 'cancelled', 'removed'].includes(item.status)) {
							items.push({
								...item,
								status: 'cancelled'
							})
						}

						return items
					}, [])

					if (kotItems.length) {
						window.printKot({
							...order,
							items: this.groupBy(kotItems, 'kot_device_id'),
							is_running_order: true
						})
					}
				}

				await this.$store.dispatch('bridgeCall', {
					methodName: 'insert',
					args: [
						'Order',
						this.bridgeName === 'ANDROID' ? this.objToJson(order) : order,
						true
					]
				})
				await this.$store.dispatch('updateTableStatus', order.tables?.map(t => t.id))

				if (!this.isMiniPlan) {
					const syncData = {
						id: this.getUniqueId(+order.id + 1),
						model_id: order.id,
						model_name: 'order',
						payload: this.objToJson({
							model_id: this.tableOrder.id,
							merchant_id: this.tableOrder.merchant_id,
							device_id: this.tableOrder.device_id,
							location_id: this.tableOrder.location_id,
							employee_id: this.tableOrder.employee?.id || this.tableOrder.employee_id || null,
							orders: [{
								id: this.tableOrder.id,
								merchant_id: this.tableOrder.merchant_id,
								device_id: this.tableOrder.device_id,
								location_id: this.tableOrder.location_id,
								employee_id: this.tableOrder.employee?.id || this.tableOrder.employee_id || null,
								created_by: this.tableOrder.created_by?.id || null,
								employee_shift_id: this.tableOrder.employee_shift_id,
								price_category_id: this.tableOrder.price_category?.id || null,
								payment_method: this.tableOrder.payment_method?.slug || 'cash',
								reservations_id: null,
								customer_id: this.tableOrder.customer?.id || null,
								order_id: this.tableOrder.order_id,
								channel: this.tableOrder.channel,
								tables: order.tables.map(table => table.id),
								kot_items: order.kot_items.map(i => ({
									...i,
									unit_measure_type: (typeof i.unit_measure_type === 'string' && i.unit_measure_type.startsWith('{'))
										? JSON.parse(i.unit_measure_type)
										: i.unit_measure_type,
									modifiers: JSON.parse(i.modifiers),
									status: 'cancelled',
									status_history: typeof i.unit_measure_type === 'string'
										? JSON.parse(i.status_history)
										: i.status_history,
									updated_at: date
								})),
								items: this.tableOrder.items.map(i => ({
									...i,
									item_inventory_id: i.inventory_id,
									item_variation_id: i.variation_id,
									item_variation_name: i.variation_name,
									single_quantity_amount: i.price,
									net_sales: i.sub_total - i.discounted_amount,
									gross_sales: i.sub_total,
									total: i.sub_total + i.tax - i.discount,
									groups: i.groups,
									tax_details: i.taxes,
									discount_details: i.discounts,
									discounts: i.discounts,
									taxes: i.taxes,
									item_discount: i.item_discount,
									unit_measure_type: i.unit_measure_type
								})),
								payment_methods: this.tableOrder.payment_methods,
								status: 'cancelled',
								receipt_code: this.tableOrder.receipt_code,
								ref_code: this.tableOrder.ref_code,
								sub_total: this.tableOrder.sub_total,
								total_tax: this.tableOrder.total_tax,
								total_charge: this.tableOrder.total_charge,
								total_price: this.tableOrder.total_price,
								total_discount: this.tableOrder.total_discount,
								total_discounted_amount: this.tableOrder.total_discounted_amount,
								total_discounted_tax: this.tableOrder.total_discounted_tax,
								payment_note: this.tableOrder.payment_note,
								amount_balance_returned: this.tableOrder.amount_balance_returned,
								total_amount: this.tableOrder.total_amount,
								tip: this.tableOrder.tip,
								round_off_amount: this.tableOrder.round_off_amount,
								charges: this.tableOrder.charges,
								taxes: this.tableOrder.taxes,
								discounts: this.tableOrder.discounts,
								card_details: this.tableOrder.card_details,
								custom_attributes: this.tableOrder.custom_attributes,
								created_at: this.tableOrder.created_at,
								updated_at: date,
								is_synced: false
							}]
						})
					}

					await this.$store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'Sync',
							this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
							true
						]
					})
					this.updateOnlineOrderStatus({
						id: order.id,
						status: order.status,
						kot_items: JSON.parse(syncData.payload).orders[0].kot_items,
						custom_attributes: this.tableOrder.custom_attributes
					})
				}

				if (this.isSecondaryDevice) {
					this.refreshTableAndOrders()
				}
			},
			async updateOrder (order) {
				await this.$store.dispatch('bridgeCall', {
					methodName: 'insert',
					args: [
						'Order',
						this.bridgeName === 'ANDROID' ? this.objToJson(order) : order,
						true
					]
				})
			},
			async printReceipt () {
				await this.updateOrder({
					id: this.tableOrder.id,
					status: 'billed',
					updated_at: new Date()
				})

				await this.$store.dispatch('updateTableStatus', this.tableOrder.tables?.map(t => t.id))
				this.updateOnlineOrderStatus({
					id: this.tableOrder.id,
					status: 'payment_requested',
					custom_attributes: this.tableOrder.custom_attributes
				})
				window.printOrder({
					...this.tableOrder,
					customers: this.tableOrder.customer ? [this.tableOrder.customer] : [],
					status: 'billed',
					reprint: true
				})

				this.refreshTable()
			},
			initSplitBill () {
				this.splitOkDisabled = false
				this.splitBillItems = JSON.parse(this.objToJson(this.tableOrder.items))
				this.$refs.splitBillModal.show()
			},
			async calculateSplitBill () {
				if (await this.$refs.validator.validate() && this.selectedSplitBillItems.length) {
					const receiptCode = await this.$store.dispatch('generateReceiptCode')

					if (!receiptCode) {
						return this.$swal({
							title: this.$t('primaryDeviceError.title'),
							text: this.$t('primaryDeviceError.text'),
							icon: 'error',
							buttons: [this.$tc('cancel', 1), this.$t('connect')]
						}).then((response) => {
							if (response) {
								this.calculateSplitBill()
							} else {
								return 0
							}
						})
					}

					this.splitOkDisabled = true

					const newDate = new Date()
					const id = this.getUniqueId()
					const date = this.$moment.utc(new Date()).format('yyyy-MM-DDTHH:mm:ss.SSSZ')
					const oldOrder = {
						...this.tableOrder,
						custom_attributes: {
							...this.tableOrder.custom_attributes,
							no_of_guest: this.tableOrder.custom_attributes?.no_of_guest ? (this.tableOrder.custom_attributes.no_of_guest - this.numberOfGuest) : null
						}
					}
					const newReceiptCode = `${this.employee.id}${this.deviceId}${newDate.valueOf()}${receiptCode}`
					const newOrder = {
						...oldOrder,
						id,
						receipt_code: newReceiptCode,
						ref_code: null,
						taxes: [],
						items: [],
						kot_items: [],
						custom_attributes: {
							...this.tableOrder.custom_attributes,
							split_order_id: this.tableOrder.id,
							no_of_guest: this.numberOfGuest,
							table_start_time: date
						}
					}

					oldOrder.items = oldOrder.items.reduce((items, item, index) => {
						const combos = item.groups.filter(g => g.type === 'combo')
						const kotIndex = []

						if (combos.length) {
							combos.forEach((combo) => {
								kotIndex.push(oldOrder.kot_items.findIndex(ki => ki.item_variation_id === combo.group_item_variation_id &&
									combo.quantity === ki.quantity &&
									ki.item_variation_name === `${item.variation_name} - ${combo.item_variation_name}`
								))
							})
						} else {
							kotIndex.push(oldOrder.kot_items.findIndex(ki => ki.item_code === item.item_code))
						}

						if (this.selectedSplitBillItems.includes(item.item_code)) {
							const originalQty = item.quantity
							const splitQty = this.splitBillItems[index].quantity

							if (splitQty < originalQty) {
								const remainingQty = originalQty - splitQty
								const remainingTaxes = []
								const newOrderItem = {
									...item,
									item_code: `${this.deviceId}${item.variation_id}${newDate.valueOf()}${newOrder.items.length}`,
									order_id: newOrder.id,
									quantity: splitQty,
									taxes: item.taxes.map((t) => {
										const oldOrderTaxIndex = oldOrder.taxes.findIndex(ot => (ot && ot.tax_id) === t.tax_id)
										const newOrderTaxIndex = newOrder.taxes.findIndex(ot => (ot && ot.tax_id) === t.tax_id)
										const tax = {
											...t,
											tax_amount: (t.tax_amount / originalQty) * remainingQty
										}

										remainingTaxes.push(tax)
										tax.tax_amount = (t.tax_amount / originalQty) * splitQty

										if (oldOrderTaxIndex > -1) {
											oldOrder.taxes[oldOrderTaxIndex].tax_amount -= tax.tax_amount

											if (oldOrder.taxes[oldOrderTaxIndex].tax_amount <= 0) {
												oldOrder.taxes.splice(oldOrderTaxIndex, 1)
											}
										}

										if (newOrderTaxIndex > -1) {
											newOrder.taxes[newOrderTaxIndex].tax_amount += tax.tax_amount
										} else {
											newOrder.taxes.push(tax)
										}

										return tax
									})
								}
								const oldOrderItem = {
									...item,
									quantity: remainingQty,
									taxes: remainingTaxes
								}

								kotIndex.forEach((index, i) => {
									newOrder.kot_items.push({
										...oldOrder.kot_items[index],
										id: this.getUniqueId(`${newOrder.id}${newOrder.kot_items.length + 1}`),
										item_code: combos.length ? `${this.deviceId}${combos[i].group_item_variation_id}${newDate.valueOf()}${i}` : newOrderItem.item_code,
										order_id: newOrder.id,
										kot_id: id,
										quantity: splitQty
									})

									if (oldOrder.kot_items[index]) {
										oldOrder.kot_items[index].quantity = remainingQty
									}
								})

								if (combos.length) {
									oldOrderItem.groups = combos.map(combo => ({
										...combo,
										quantity: remainingQty
									}))
									newOrderItem.groups = combos.map(combo => ({
										...combo,
										quantity: splitQty
									}))
								}

								newOrder.items.push(newOrderItem)
								items.push(oldOrderItem)
							} else {
								const oldKotItems = [...oldOrder.kot_items]

								kotIndex.forEach((index, i) => {
									newOrder.kot_items.push({
										...oldOrder.kot_items[index],
										id: this.getUniqueId(`${newOrder.id}${newOrder.kot_items.length + 1}`),
										item_code: `${this.deviceId}${
											combos.length ? combos[i].group_item_variation_id : item.variation_id
										}${newDate.valueOf()}${
											combos.length ? newOrder.kot_items.length : newOrder.items.length
										}`,
										order_id: newOrder.id,
										kot_id: id
									})
									oldKotItems.splice(index - i, 1)
								})

								oldOrder.kot_items = oldKotItems
								item.taxes = item.taxes.map((t) => {
									const oldOrderTaxIndex = oldOrder.taxes.findIndex(ot => (ot && ot.tax_id) === t.tax_id)

									if (oldOrderTaxIndex > -1) {
										oldOrder.taxes[oldOrderTaxIndex].tax_amount -= t.tax_amount

										if (oldOrder.taxes[oldOrderTaxIndex].tax_amount <= 0) {
											oldOrder.taxes.splice(oldOrderTaxIndex, 1)
										}
									}

									return t
								})
								newOrder.items.push({
									...item,
									item_code: `${this.deviceId}${item.variation_id}${newDate.valueOf()}${newOrder.items.length}`,
									order_id: newOrder.id
								})
							}
						} else {
							items.push(item)
						}

						return items
					}, [])
					await this.addOrderItemsToCart(oldOrder)

					let transformedOrder = await this.transformOrder(oldOrder)

					await this.$store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'Order',
							this.bridgeName === 'ANDROID' ? this.objToJson(transformedOrder) : transformedOrder,
							true
						]
					})
					await this.addOrderItemsToCart(newOrder)
					transformedOrder = await this.transformOrder(newOrder)
					await this.$store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'Order',
							this.bridgeName === 'ANDROID' ? this.objToJson(transformedOrder) : transformedOrder,
							true
						]
					})
					this.$store.commit('resetCart')
					await this.$store.dispatch('updateLastOrder', { orderCount: newReceiptCode.substr(-4), date: newDate })
					this.$bvModal.hide('split-bill-modal')

					if (this.isSecondaryDevice) {
						this.refreshTableAndOrders()
					}
				} else {
					this.$swal({
						title: this.$t('splitItemsError.title'),
						text: this.$t('splitItemsError.text'),
						icon: 'error',
						button: this.$t('ok')
					})
					this.splitOkDisabled = false
				}
			},
			initMergeBill () {
				this.$store.commit('setState', {
					key: 'selectedTables',
					value: [this.selectedTable]
				})
				this.$store.commit('setState', {
					key: 'selectedOrder',
					value: this.tableOrder
				})
				this.$bvModal.show('orders-modal')
			},
			initMoveBill () {
				this.$store.commit('setState', {
					key: 'selectedOrder',
					value: this.tableOrder
				})
				this.$bvModal.show('tables-modal')
			},
			async processTransferOrder () {
				const date = this.$moment.utc(new Date()).format('yyyy-MM-DDTHH:mm:ss.SSSZ')
				const order = {
					...this.tableOrder,
					created_by: this.tableOrder.created_by ? this.tableOrder.created_by.id : null,
					charges: this.objToJson(this.tableOrder.charges),
					discounts: this.objToJson(this.tableOrder.discounts),
					card_details: this.objToJson(this.tableOrder.card_details),
					custom_attributes: this.objToJson(this.tableOrder.custom_attributes),
					items: this.tableOrder.items.reduce((items, item) => {
						item = {
							...item,
							unit_measure_type: typeof item.unit_measure_type === 'object'
								? this.objToJson(item.unit_measure_type)
								: item.unit_measure_type,
							groups: this.objToJson(item.groups),
							item_discount: this.objToJson(item.item_discount),
							discounts: this.objToJson(item.discounts),
							taxes: this.objToJson(item.taxes),
							custom_attributes: this.objToJson(item.custom_attributes),
							updated_at: date
						}

						items.push(item)

						return items
					}, []),
					kot_items: this.tableOrder.kot_items.map(ki => ({
						...ki,
						unit_measure_type: typeof ki.unit_measure_type === 'object'
							? this.objToJson(ki.unit_measure_type)
							: ki.unit_measure_type,
						modifiers: this.objToJson(ki.modifiers),
						status_history: this.objToJson(ki.status_history)
					})),
					tables: this.selectedTables.map((table) => {
						return {
							...table,
							layout: typeof table.layout === 'object' ? this.objToJson(table.layout) : table.layout,
							custom_attributes: this.selectedTables.length ? this.objToJson(table.custom_attributes) : '{}',
							is_occupied: true,
							status: 'occupied',
							updated_at: date
						}
					}),
					taxes: this.objToJson(this.tableOrder.taxes),
					payment_method: {
						...this.tableOrder.payment_method,
						updated_at: date
					},
					payment_methods: this.tableOrder.payment_methods.map((pm) => {
						return {
							...pm,
							updated_at: date
						}
					}),
					updated_at: date
				}

				await this.$store.dispatch('bridgeCall', {
					methodName: 'insert',
					args: [
						'Order',
						this.bridgeName === 'ANDROID' ? this.objToJson(order) : order,
						true
					]
				})
				await this.$store.dispatch('updateTableStatus', this.tableOrder.tables.filter((t) => {
					return this.selectedTables.findIndex(st => st.id === t.id) === -1
				}).map(t => t.id))

				this.$bvModal.hide('tables-modal')
				this.selectedTables = []
				this.$store.commit('setCart', { table: null, waiter: null })
				this.$store.commit('setState', {
					key: 'selectedOrder',
					value: null
				})

				if (this.isSecondaryDevice) {
					this.refreshTableAndOrders()
				}
			},
			async processMergeOrder (selectedOrders) {
				const date = this.$moment.utc(new Date()).format('yyyy-MM-DDTHH:mm:ss.SSSZ')
				const order = {
					...this.tableOrder
				}

				for await (const selectedOrder of selectedOrders) {
					selectedOrder.items.forEach((item) => {
						const orderItemIndex = this.filterItem(order.items, item, 'index')

						if (orderItemIndex !== -1) {
							const newQty = order.items[orderItemIndex].quantity + item.quantity

							order.items[orderItemIndex].taxes = order.items[orderItemIndex].taxes.map((tax) => {
								return {
									...tax,
									tax_amount: (tax.tax_amount / order.items[orderItemIndex].quantity) * newQty
								}
							})

							item.taxes.forEach((t) => {
								const oldOrderTaxIndex = order.taxes.findIndex(ot => (ot && ot.tax_id) === t.tax_id)

								if (oldOrderTaxIndex > -1) {
									order.taxes[oldOrderTaxIndex].tax_amount = (order.taxes[oldOrderTaxIndex].tax_amount / order.items[orderItemIndex].quantity) * newQty
								} else {
									order.taxes.push(t)
								}
							})

							order.items[orderItemIndex].quantity = newQty
						} else {
							order.items.push(item)
						}
					})
					order.kot_items = order.kot_items.concat(selectedOrder.kot_items)

					if (order.custom_attributes.no_of_guest) {
						order.custom_attributes.no_of_guest = +order.custom_attributes.no_of_guest + (+selectedOrder.custom_attributes?.no_of_guest || 0)
					}

					const deleteOrder = {
						id: selectedOrder.id,
						status: 'merged',
						receipt_code: selectedOrder.receipt_code,
						updated_at: date,
						is_synced: true
					}

					await this.$store.dispatch('bridgeCall', {
						methodName: 'insert',
						args: [
							'Order',
							this.bridgeName === 'ANDROID' ? this.objToJson(deleteOrder) : deleteOrder,
							true
						]
					})

					await this.$store.dispatch('updateTableStatus', selectedOrder.tables?.map(t => t.id))
				}

				order.kot_items = order.kot_items.reduce((items, ki) => {
					const itemIndex = order.items.findIndex((item) => {
						const kotIndex = items.findIndex(i => i.item_variation_name === ki.item_variation_name)

						return item.item_code === ki.item_code || (kotIndex === -1 && item.groups.length && item.groups[0].type === 'combo' && ki.item_code.includes(item.variation_id))
					})

					if (itemIndex !== -1) {
						items.push({
							...ki,
							quantity: order.items[itemIndex].quantity,
							unit_measure_type: typeof ki.unit_measure_type !== 'object'
								? JSON.parse(ki.unit_measure_type)
								: ki.unit_measure_type
						})
					}

					return items
				}, [])
				order.items = order.items.map((item) => {
					item.groups = item.groups.map(g => (
						{
							...g,
							quantity: item.quantity
						})
					)

					return item
				})
				order.additive_tax = 0
				order.inclusive_tax = 0

				await this.addOrderItemsToCart(order)

				const transformedOrder = await this.transformOrder(order)

				await this.$store.dispatch('bridgeCall', {
					methodName: 'insert',
					args: [
						'Order',
						this.bridgeName === 'ANDROID' ? this.objToJson(transformedOrder) : transformedOrder,
						true
					]
				})
				this.$store.commit('resetCart')
				this.getTableOrders()
				this.$store.commit('setState', {
					key: 'selectedOrder',
					value: null
				})
				this.selectedTables = []
				this.$bvModal.hide('orders-modal')

				if (this.isSecondaryDevice) {
					this.refreshTableAndOrders()
				}
			},
			transformOrder (order) {
				const date = new Date()

				return {
					...order,
					employee_id: order.employee?.id,
					created_by: order.created_by?.id || null,
					price_category_id: order.price_category?.id || null,
					payment_method: {
						...order.payment_method,
						updated_at: date
					},
					payment_methods: order.payment_methods.map((pm) => {
						return {
							...pm,
							updated_at: date
						}
					}),
					tables: order.tables.map(t => ({
						...t,
						layout: typeof t.layout === 'object' ? this.objToJson(t.layout) : this.objToJson(t.layout),
						custom_attributes: this.objToJson(t.custom_attributes)
					})),
					charges: this.objToJson(order.charges),
					discounts: this.objToJson(order.discounts),
					card_details: this.objToJson(order.card_details),
					taxes: this.objToJson(order.taxes),
					kot_items: order.kot_items.map(ki => ({
						...ki,
						unit_measure_type: typeof ki.unit_measure_type === 'object'
							? this.objToJson(ki.unit_measure_type)
							: ki.unit_measure_type,
						modifiers: this.objToJson(ki.modifiers),
						status_history: this.objToJson(ki.status_history),
						updated_at: date
					})),
					items: order.items.map((item) => {
						const cartItemIndex = this.filterItem(this.cart.items, item, 'index')

						return {
							...item,
							unit_measure_type: typeof item.unit_measure_type === 'object'
								? this.objToJson(item.unit_measure_type)
								: item.unit_measure_type,
							groups: this.objToJson(item.groups),
							item_discount: this.objToJson(item.item_discount),
							discounts: this.objToJson(item.discounts),
							custom_attributes: this.objToJson(item.custom_attributes),
							taxes: this.objToJson(item.taxes),
							discount: this.cart.items[cartItemIndex].discountedAmount + this.cart.items[cartItemIndex].discountedTax,
							discounted_amount: this.cart.items[cartItemIndex].discountedAmount,
							discounted_tax: this.cart.items[cartItemIndex].discountedTax,
							tax: this.cart.items[cartItemIndex].taxAmount,
							sub_total: this.cart.items[cartItemIndex].subtotal,
							updated_at: date
						}
					}),
					custom_attributes: this.objToJson(order.custom_attributes),
					tip: this.cart.price.tip,
					round_off_amount: this.cart.price.roundOff,
					sub_total: this.cart.price.subtotal,
					total_charge: this.cart.price.charge,
					total_discount: this.cart.price.discount,
					total_discounted_amount: this.cart.price.discountedAmount,
					total_discounted_tax: this.cart.price.discountedTax,
					total_price: this.cart.price.total,
					total_tax: this.cart.price.tax,
					updated_at: date
				}
			},
			updateOnlineOrderStatus (order) {
				if (order.custom_attributes?.channel_id && Array.isArray(order.custom_attributes.tables)) {
					this.$store.dispatch('updateOnlineOrderStatus', {
						orderId: order.id,
						data: {
							state: order.status,
							kot_items: order.kot_items
						}
					})
				}
			},
			keyboardEvents (e) {
				if ([
					'AltLeft', 'AltRight', 'KeyA', 'KeyB', 'KeyC', 'KeyD', 'KeyE', 'KeyF', 'KeyG', 'KeyH', 'KeyI',
					'KeyJ', 'KeyK', 'KeyL', 'KeyM', 'KeyN', 'KeyO', 'KeyP', 'KeyQ', 'KeyR',
					'KeyS', 'KeyT', 'KeyU', 'KeyV', 'KeyW', 'KeyX', 'KeyY', 'KeyZ',
					'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'
				].includes(e.code)) {
					this.keystrokes += e.code

					if (/^Alt/.test(this.keystrokes) || /^F(1[0-2]?|[2-9])$/.test(e.code)) {
						if (/^Key/.test(e.code) || /^F(1[0-2])$/.test(e.code)) {
							const element = Object.keys(this.keyboardShortcuts).find(key => this.keyboardShortcuts[key] === e.code.replace(/Key/g, ''))

							let selector

							if (element === 'complete_table_order') {
								selector = '//*[@id="complete_table_order"]'
							}

							if (selector) {
								document.evaluate(
									selector,
									document,
									null,
									XPathResult.FIRST_ORDERED_NODE_TYPE,
									null
								).singleNodeValue?.click()

								this.keystrokes = ''
							}

							this.keystrokes = ''
						}
					}
				} else {
					this.keystrokes = ''
				}
			},
			async refreshTable () {
				if (this.selectedTable) {
					let selectedTable = await this.$store.dispatch('bridgeCall', {
						methodName: 'getTables',
						args: [this.deviceId, this.locationId, this.objToJson({
							id: this.selectedTable.id
						})]
					})

					selectedTable = typeof selectedTable === 'string' ? JSON.parse(selectedTable) : selectedTable
					this.selectedTable = selectedTable.data ? selectedTable.data[0] : selectedTable[0]
				}
			},
			async refreshTableAndOrders () {
				await this.refreshTable()
				await this.getTableOrders()
			},
			async updateTableStatus (status) {
				let updateTable = null

				this.selectedTable.status = status
				updateTable = {
					id: this.selectedTable.id,
					status
				}

				await this.$bridge.insert(
					'Table',
					this.bridgeName === 'ANDROID' ? this.objToJson(updateTable) : updateTable,
					true
				)
			}
		}
	}
